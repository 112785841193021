<template>
    <div id="supervisor-supplier-items">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card>
                <b-row>
                    <b-col cols="12" md="7">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                    <b-col cols="12" md="5" class="text-right">
                        <h3>{{ entityName }}</h3>
                    </b-col>
                </b-row>
                <ag-grid :agGrid="agGrid" ref="agGrid" pageTitle="Liste des articles" @refresh="loadData"
                    @cellDoubleClicked="cellDoubleClicked" :autoSizeColumns="true" :showHeader="false" />

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BLinkCellRenderer from '@/views/product/BLinkCellRendererWithImage'
import IconCellRenderer from "@/views/product/IconCellRenderer.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            entityName: "",
            searchQuery: "",
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, },
                        width: 350
                    },
                    {
                        headerName: "Type",
                        field: "type",
                    },
                    {
                        headerName: "Famille",
                        field: "familyDescription",
                    },
                    {
                        headerName: "Sous-Famille",
                        field: "subFamilyDescription",
                    },
                    {
                        headerName: "DCI",
                        field: "dciDescription",
                    },
                    {
                        headerName: "Code PCT",
                        field: "pct",
                    },
                    {
                        headerName: "Barcode",
                        field: "barCode",
                    },
                    {
                        headerName: "Cartonnage",
                        field: "packaging",
                    },
                    {
                        headerName: "Prix H.T",
                        field: "priceHT",
                    },
                    {
                        headerName: "Prix T.T.C",
                        field: "priceTTC",
                    },
                    {
                        headerName: "Prix Public",
                        field: "publicPrice",
                    },
                    {
                        headerName: "TVA",
                        field: "vat",
                    },
                    {
                        headerName: "Forme",
                        field: "form",
                    },
                    {
                        headerName: "Présentation",
                        field: "presentation",
                    },
                    {
                        headerName: "Conditionnement primaire",
                        field: "primaryPackaging",
                    },
                    {
                        headerName: "Spécification",
                        field: "specificationPrimaryPackaging",
                    },
                    {
                        headerName: "Classement VEIC",
                        field: "veic",
                    },
                    {
                        headerName: "Classe Thérapeutique",
                        field: "_class",
                    },
                    {
                        headerName: "Sous Classe",
                        field: "veic",
                    },
                    {
                        headerName: "Classement VEIC",
                        field: "subClass",
                    },
                    {
                        headerName: "Laboratoire",
                        field: "vendorDescription",
                    },
                    {
                        headerName: "Générique/Princeps",
                        field: "category",
                    },
                    {
                        headerName: "Tableau",
                        field: "_table",
                    },
                    {
                        headerName: "Tableau",
                        field: "_table",
                    },
                    {
                        headerName: "CNAM",
                        field: "apcnam",
                        cellRendererFramework: 'iconCellRenderer',
                        width: 100
                    },
                    {
                        headerName: "Bloqué",
                        field: "bloqued",
                        cellRendererFramework: 'iconCellRenderer',
                        width: 100
                    },
                    {
                        headerName: "Créé le",
                        field: "createdAt",
                    },
                    {
                        headerName: "Modifié At",
                        field: "updatedAt",
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        tableData() {
            return this.$store.getters["app-items/geItems"];
        },
    },
    watch: {
        tableData(products) {
            if (products.length > 0) {
                this.showLoading = false;
                // console.log(products);
                this.agGrid.rows = [...products.filter(item => item.vendorCode === this.$route.params.code)]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
                setTimeout(() => {
                    // console.log(this.$route.params.code);
                    // console.log(this.agGrid.rows);
                    this.entityName = this.agGrid.rows[0].vendorDescription;
                }, 1000);
            }
        },
    },
    created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.iconCellRenderer = IconCellRenderer;
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        if (this.$store.getters["app-items/geItems"].length > 0) {
            setTimeout(() => {
                this.agGrid.rows = [...this.$store.getters["app-items/geItems"].filter(item => item.vendorCode === this.$route.params.code)].sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
                setTimeout(() => {
                    this.entityName = this.agGrid.rows[0].vendorDescription;
                }, 1000);
            }, 1000);
        }
    },
    methods: {
        async showDetails(data) {
            this.$router.push({ name: "supervisor-item-sheet", params: { code: data.code } });
        },
        loadData() {
            this.showLoading = true;
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND');
        },
        updateSearchQuery(val) {
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.showDetails(param.data);
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>